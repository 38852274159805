import { useMemo, useState } from "react";

import { SelectOptionPropsV2 } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import useClickOutsideOfElement from "@sellernote/_shared/src/hooks/common/useClickOutsideOfElement";
import { getI18nInstanceForDesignSystem } from "@sellernote/_shared/src/i18n/i18nForDesignSystem";
import { noop } from "@sellernote/_shared/src/utils/common/etc";
import ExpandArrowLessFilledIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExpandArrowLessFilledIcon";
import ExpandArrowMoreFilledIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExpandArrowMoreFilledIcon";
import InfoFilledIcon from "@sellernote/_sds-v2/src/components/svgIcons/InfoFilledIcon";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";

import Tooltip from "../../Tooltip";
import FormStyled from "../index.styles";
import Styled from "./index.styles";

export default function SelectOption<T>({
  size,
  labelInfo,
  options,
  onSelect,
  placeholder,
  width,
  selectedOptionValue,
  errorMessage,
  disabled,
  className,
  isExpandIconHidden,
  onOpen,
  onClose,
  optionListPosition = "bottom",
}: SelectOptionPropsV2<T>) {
  const { Trans } = getI18nInstanceForDesignSystem();

  const [opensOptionList, setOpensOptionList] = useState(false);

  const handleOptionListOpen = () => {
    setOpensOptionList(true);
    onOpen?.();
  };

  const handleOptionListClose = () => {
    setOpensOptionList(false);
    onClose?.();
  };

  const handleOptionListToggle = () => {
    opensOptionList ? handleOptionListClose() : handleOptionListOpen();
  };

  const handleOptionSelect = (value: T) => {
    onSelect(value);
    handleOptionListClose();
  };

  const { targetElementRef: selectOptionElementRef } = useClickOutsideOfElement(
    {
      onClickOutside: handleOptionListClose,
    }
  );

  const selectedOption = useMemo(() => {
    return options.find((v) => v.value === selectedOptionValue);
  }, [options, selectedOptionValue]);

  const arrowColor = useMemo(() => {
    if (disabled) return COLOR.grayScale_400;

    return size === "small" ? COLOR.grayScale_500 : COLOR.grayScale_700;
  }, [disabled, size]);

  return (
    <Styled.container
      width={width}
      size={size}
      labelWidth={labelInfo?.width}
      disabled={disabled}
      className={`${className ? className : ""} select-option`}
      ref={selectOptionElementRef}
    >
      {labelInfo && (
        <label
          className={`${(labelInfo.isLabelHidden && "visually-hidden") ?? ""}`}
        >
          {labelInfo.label}

          {labelInfo.tooltipInfo && (
            <Tooltip
              title={labelInfo.tooltipInfo.title}
              desc={labelInfo.tooltipInfo.desc}
              width={labelInfo.tooltipInfo.width}
              position="bottomLeft"
              disabled={disabled}
            >
              <InfoFilledIcon
                width={16}
                height={16}
                color={
                  disabled ? TEXT_COLOR.black_disabled : TEXT_COLOR.black_3
                }
              />
            </Tooltip>
          )}
        </label>
      )}

      <div className="select-container">
        <Styled.inputWrapper
          onClick={disabled ? noop : handleOptionListToggle}
          size={size}
          isActive={opensOptionList}
          isVisiblePlaceholder={!selectedOption}
          disabled={disabled}
        >
          <span className="selected-option-value">
            {selectedOption?.label || placeholder || (
              <Trans i18nKey="sds-v2:공통_선택" />
            )}
          </span>

          {opensOptionList && !isExpandIconHidden && (
            <ExpandArrowLessFilledIcon
              width={16}
              height={16}
              color={arrowColor}
              className="icon"
            />
          )}

          {!opensOptionList && !isExpandIconHidden && (
            <ExpandArrowMoreFilledIcon
              width={16}
              height={16}
              color={arrowColor}
              className="icon"
            />
          )}

          {opensOptionList && (
            <FormStyled.optionList
              onScroll={(e) => e.stopPropagation()}
              onWheel={(e) => e.stopPropagation()}
              size={size}
              disabled={disabled}
              optionListPosition={optionListPosition}
            >
              {options.map(({ label, value, disabled }, i) => (
                <li
                  key={`select-option-item-${i}`}
                  className={`select-option-item ${disabled ? "disabled" : ""}`}
                  onClick={disabled ? noop : () => handleOptionSelect(value)}
                >
                  {label}
                </li>
              ))}
            </FormStyled.optionList>
          )}
        </Styled.inputWrapper>

        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </Styled.container>
  );
}
