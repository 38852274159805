import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import useResetStateWithTempKey from "../../useResetStateWithTempKey";
import ExistingLocation from "../ExistingLocation";
import CompleteMovement from "./CompleteMovement";
import SkuDetail from "./SkuDetail";
import useInventoryMovement from "./useInventoryMovement";

function InventoryMovementSplitWithKey() {
  const {
    existingLocation,
    setExistingLocation,

    listOfInventoryToMove,

    inventoryDetailByLocation,

    AddedItemListToMove,

    ResponseHandlerOfGettingInventoryDetailByLocation,
  } = useInventoryMovement();

  return (
    <>
      <ExistingLocation
        location={existingLocation?.barCode}
        setExistingLocation={setExistingLocation}
      />

      {!!existingLocation && !!inventoryDetailByLocation && (
        <>
          <SkuDetail
            skuId={inventoryDetailByLocation.skuId}
            skuBarcode={inventoryDetailByLocation.sku.barCode}
            availableQty={inventoryDetailByLocation.availableQty}
          />

          {AddedItemListToMove}

          <CompleteMovement
            existingLocationId={existingLocation.id}
            skuId={inventoryDetailByLocation.skuId}
            listOfInventoryToMove={listOfInventoryToMove}
          />
        </>
      )}

      {ResponseHandlerOfGettingInventoryDetailByLocation}
    </>
  );
}

function InventoryMovementSplit() {
  const tempKey = useResetStateWithTempKey();

  return (
    <Layout
      navigator={{
        title: "재고 이동 / 분할",
      }}
    >
      <InventoryMovementSplitWithKey key={tempKey} />
    </Layout>
  );
}

export default withRequireAuth(InventoryMovementSplit);
