import { useEffect, useState } from "react";

import { TableSubRowAccordionStatus } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { usePrevious } from "@sellernote/_shared/src/utils/common/hook";

export default function useAccordionStatus(
  initialAccordionStatus: TableSubRowAccordionStatus
) {
  const previousInitialAccordionStatus = usePrevious(initialAccordionStatus);

  const [accordionStatus, setAccordionStatus] =
    useState<TableSubRowAccordionStatus>(initialAccordionStatus);

  /**
   * initialAccordionStatus가 바꼈으면 accordionStatus을 다시 초기화
   * - initialAccordionStatus를 바꿈으로써 컴포넌트 외부에서 accordionStatus를 클릭(터치)하지 않고도 컨트롤 할 수 있다. (ex. 특정 상황에 자동으로 아코디언이 열리게 할 수 있음)
   */
  useEffect(() => {
    const isInitialAccordionStatusChanged = (() => {
      if (!initialAccordionStatus || !previousInitialAccordionStatus) {
        return false;
      }

      return previousInitialAccordionStatus !== initialAccordionStatus;
    })();

    if (isInitialAccordionStatusChanged) {
      setAccordionStatus(initialAccordionStatus);
    }
  }, [initialAccordionStatus, previousInitialAccordionStatus]);

  return { accordionStatus, setAccordionStatus };
}
