export const COLOR = {
  primary_900: "#3B418F",
  primary_800: "#475FB0",
  primary_700: "#4D70C3",
  primary_600: "#5482D6",
  primary_500: "#598FE4",
  primary_400: "#669FE9",
  primary_300: "#7BB0ED",
  primary_200: "#9cc6f4",
  primary_100: "#C1DCF8",
  primary_50: "#E5F1FC",

  secondary_900: "#2e5c60",
  secondary_800: "#3c7f8a",
  secondary_700: "#4293a2",
  secondary_600: "#49a8bb",
  secondary_500: "#4eb8ce",
  secondary_400: "#54c3d6",
  secondary_300: "#66cdde",
  secondary_200: "#8cdce9",
  secondary_100: "#b7eaf2",
  secondary_50: "#e2f6fa",

  point_900: "#702c4e",
  point_800: "#94355b",
  point_700: "#aa3962",
  point_600: "#c03d69",
  point_500: "#d1416d",
  point_400: "#d65481",
  point_300: "#dd6e97",
  point_200: "#e595b4",
  point_100: "#efbed2",
  point_50: "#f8e5ed",

  grayScale_50: "#FAFAFA",
  grayScale_100: "#F5F5F5",
  grayScale_200: "#EEEEEE",
  grayScale_300: "#E0E0E0",
  grayScale_400: "#BDBDBD",
  grayScale_500: "#9E9E9E",
  grayScale_600: "#757575",
  grayScale_700: "#616161",
  grayScale_800: "#424242",
  grayScale_900: "#212121",

  bk: "#000000",
  bk_80: "rgba(0, 0, 0, 0.8)",
  bk_60: "rgba(0, 0, 0, 0.6)",
  bk_40: "rgba(0, 0, 0, 0.4)",
  bk_10: "rgba(0, 0, 0, 0.1)",
  bk_8: "rgba(0, 0, 0, 0.08)",
  bk_4: "rgba(0, 0, 0, 0.04)",
  bk_2: "rgba(0, 0, 0, 0.02)",

  wh: "#ffffff",
  wh_80: "rgba(255, 255, 255, 0.8)",
  wh_60: "rgba(255, 255, 255, 0.6)",
  wh_40: "rgba(255, 255, 255, 0.4)",

  bgColor_1: "#F9FAFB",
  bgColor_2: "#F2F4F6",

  success_400: "#77CC84",
  success_300: "#c5e8c9",
  success_200: "#9fdaa7",
  success_100: "#e7f6e9",

  error_400: "#FF002E",
  error_300: "#FF3F4C",
  error_200: "#fe969a",
  error_100: "#fdeaee",

  good_400: "#FFD600",
  good_300: "#feea3f",
  good_200: "#fff394",
  good_100: "#fffde5",
};

export const RADIX_COLOR = {
  indigo1: "#fdfdfe",
  indigo2: "#f7f9ff",
  indigo3: "#edf2fe",
  indigo4: "#e1e9ff",
  indigo5: "#d2deff",
  indigo6: "#c1d0ff",
  indigo7: "#abbdf9",
  indigo8: "#8da4ef",
  indigo9: "#3e63dd",
  indigo10: "#3358d4",
  indigo11: "#3a5bc7",
  indigo12: "#1f2d5c",

  orange1: "#fefcfb",
  orange2: "#fff7ed",
  orange3: "#ffefd6",
  orange4: "#ffdfb5",
  orange5: "#ffd19a",
  orange6: "#ffc182",
  orange7: "#f5ae73",
  orange8: "#ec9455",
  orange9: "#f76b15",
  orange10: "#ef5f00",
  orange11: "#cc4e00",
  orange12: "#582d1d",

  green1: "#fbfefc",
  green2: "#f4fbf6",
  green3: "#e6f6eb",
  green4: "#d6f1df",
  green5: "#c4e8d1",
  green6: "#adddc0",
  green7: "#8eceaa",
  green8: "#5bb98b",
  green9: "#30a46c",
  green10: "#2b9a66",
  green11: "#218358",
  green12: "#193b2d",

  blue1: "#fbfdff",
  blue2: "#f4faff",
  blue3: "#e6f4fe",
  blue4: "#d5efff",
  blue5: "#c2e5ff",
  blue6: "#acd8fc",
  blue7: "#8ec8f6",
  blue8: "#5eb1ef",
  blue9: "#0090ff",
  blue10: "#0588f0",
  blue11: "#0d74ce",
  blue12: "#113264",
};

export const TEXT_COLOR = {
  black_1: "#000000",
  black_2: "rgba(0, 0, 0, 0.8)",
  black_3: "rgba(0, 0, 0, 0.5)",
  black_4: "rgba(0, 0, 0, 0.35)",
  black_5: "rgba(0, 0, 0, 0.25)",
  black_disabled: "rgba(0, 0, 0, 0.25)",

  white_1: "#FFFFFF",
  white_2: "rgba(255, 255, 255, 0.7)",
  white_disabled: "rgba(255, 255, 255, 0.4)",
};
