import { useCallback, useMemo } from "react";

import {
  ColSpan,
  NoBorderBottomForTd,
  TableColumnInfo,
  TableDataListItem,
  TableDataListItemWithFlattenSubRow,
  TableSubRowAccordionStatus,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";

import Styled from "../../index.styles";
import { TableBodyProps } from "..";
import useAccordionStatus from "./useAccordionStatus";

export default function TableBodyRow<T>({
  isOverflowed,
  tableRef,
  columnInfo,
  rowData,
  rowMinHeight,
  rowInfoToHighlight,
  rowToHighlightRef,
  isBlinkOnHighlightRow,
  disabledRowHoverBgColor,
}: Pick<
  TableBodyProps<T>,
  | "isOverflowed"
  | "tableRef"
  | "columnInfo"
  | "rowMinHeight"
  | "rowInfoToHighlight"
  | "rowToHighlightRef"
  | "isBlinkOnHighlightRow"
> & {
  rowData: TableDataListItem<T>;
  disabledRowHoverBgColor?: boolean;
}) {
  const { accordionStatus, setAccordionStatus } = useAccordionStatus(
    rowData.subRowInfo?.initialAccordionStatus
  );

  /**
   * mainRow에 포함된 subRow가 펼쳐진 형태로 정리된 rowDataList.
   * - 원본 데이터(`rowData)는 mainRow 하위 데이터(`subRowInfo?.subRowList`)형태이므로 개별 Row로 표시될 수 있도록 정리함
   */
  const rowDataListWithFlattenSubRow: TableDataListItemWithFlattenSubRow<T>[] =
    useMemo(() => {
      const { subRowInfo, ...mainRow } = rowData;

      if (!subRowInfo) {
        return [mainRow];
      }

      /**
       * mainRow의 subRowInfo상태가 주입된 subRowList
       * - mainRow의 accordion상태를 subRow에 반영하기 위함이다
       */
      const hydratedSubRowList =
        subRowInfo?.subRowList.map((v, i) => {
          const hydratedSubRow: TableDataListItemWithFlattenSubRow<T> = {
            ...v,
            isSubRow: true,
          };

          return hydratedSubRow;
        }) || [];

      // mainRow와 mainRow에 속한 subRow list를 펼쳐 하나의 배열로 만든다.
      return [mainRow, ...hydratedSubRowList];
    }, [rowData]);

  const makeColumns = useCallback(
    (rowData: TableDataListItemWithFlattenSubRow<T>) => {
      const result = [];

      let noBorderBottomForTd: NoBorderBottomForTd[] = [];
      for (const [key, value] of Object.entries(rowData)) {
        if (
          (key as keyof TableDataListItem<T>) === "disabled" ||
          (key as keyof TableDataListItem<T>) === "rowKey" ||
          (key as keyof TableDataListItem<T>) === "rowFontColor" ||
          (key as keyof TableDataListItem<T>) === "rowBackgroundColor" ||
          (key as keyof TableDataListItem<T>) === "handleRowClick" ||
          (key as keyof TableDataListItem<T>) === "noBorderBottom" ||
          (key as keyof TableDataListItem<T>) === "rowClassName" ||
          (key as keyof TableDataListItem<T>) === "isSubRow" ||
          (key !== "colSpan" &&
            key !== "noBorderBottomForTd" &&
            !Object.keys(columnInfo).includes(key))
        ) {
          continue;
        }

        const columnPortion =
          columnInfo[key as keyof TableColumnInfo<T>]?.portion;

        const columnMinWidth =
          columnInfo[key as keyof TableColumnInfo<T>]?.minWidth;

        const columnMaxWidth =
          columnInfo[key as keyof TableColumnInfo<T>]?.maxWidth;

        const columnFixedWidth =
          columnInfo[key as keyof TableColumnInfo<T>]?.fixedWidth;

        if ((key as keyof TableDataListItem<T>) === "colSpan") {
          const colSpanValue = value as ColSpan;

          result.push(
            <Styled.TableTd
              colSpan={colSpanValue.value}
              key={key}
              portion={columnPortion}
              minWidth={columnMinWidth}
              maxWidth={columnMaxWidth}
              fixedWidth={columnFixedWidth}
              isOverflowed={isOverflowed}
              noBorderBottom={rowData.noBorderBottom}
              rowMinHeight={rowMinHeight}
              isSubRow={rowData.isSubRow}
              style={{
                ...(colSpanValue.hasFullWidth && {
                  width: tableRef.current.scrollWidth,
                }),
              }}
            >
              {colSpanValue.content}
            </Styled.TableTd>
          );

          continue;
        }

        /** noBorderBottomForTd */
        if ((key as keyof TableDataListItem<T>) === "noBorderBottomForTd") {
          noBorderBottomForTd = value as NoBorderBottomForTd[];

          continue;
        }

        const noBorderBottomForTdKeys = noBorderBottomForTd.map((v) => v.key);
        if (noBorderBottomForTdKeys.includes(key)) {
          const tdInfo = noBorderBottomForTd.find((v) => v.key === key);

          result.push(
            <Styled.TableTd
              noBorderBottomForTd={tdInfo}
              key={key}
              portion={columnPortion}
              minWidth={columnMinWidth}
              maxWidth={columnMaxWidth}
              fixedWidth={columnFixedWidth}
              isOverflowed={isOverflowed}
              noBorderBottom={rowData.noBorderBottom}
              rowMinHeight={rowMinHeight}
              isSubRow={rowData.isSubRow}
            >
              {value}
            </Styled.TableTd>
          );

          continue;
        }

        /** 일반 Td */
        result.push(
          <Styled.TableTd
            key={key}
            portion={columnPortion}
            minWidth={columnMinWidth}
            maxWidth={columnMaxWidth}
            fixedWidth={columnFixedWidth}
            isOverflowed={isOverflowed}
            noBorderBottom={rowData.noBorderBottom}
            rowMinHeight={rowMinHeight}
            isSubRow={rowData.isSubRow}
          >
            {value}
          </Styled.TableTd>
        );
      }

      return result;
    },
    [columnInfo, isOverflowed, rowMinHeight, tableRef]
  );

  return (
    <>
      {rowDataListWithFlattenSubRow.map((rowData) => {
        /**
         * Accordion기능을 사용하는 지 여부
         * - 사용하지 않을 수도 있다.
         * - 초기값 존재여부로 사용여부를 판단한다 (undefined면 사용하지 않는 것으로 판단)
         */
        const usesAccordion = Boolean(accordionStatus);

        /**
         * Accordion이 closed상태인 경우, subRow만 닫힌다(보이지않게 된다)
         */
        const isClosed = rowData.isSubRow && accordionStatus === "closed";

        const handleRowClick = () => {
          // 아코디언을 사용하는 경우 아코디언 액션을 먼저 수행한다.
          // mainRow인 경우만 아코디언 액션을 수행한다.
          if (usesAccordion && !rowData.isSubRow) {
            const oppositeStatus: TableSubRowAccordionStatus = (() => {
              if (accordionStatus === "opened") return "closed";

              if (accordionStatus === "closed") return "opened";
            })();

            if (oppositeStatus) {
              setAccordionStatus(oppositeStatus);
            }
          }

          if (rowData.handleRowClick) {
            rowData.handleRowClick();
          }
        };

        return (
          // TODO: 아코디언 기능을 사용하는 경우, '기능사용 여부, 열림, 닫힘'에 대한 UI 단서를 추가할예정 (아직 디자인 확정이 안되어 추후 디자인 나오면 반영할 예정)
          <Styled.TableTr
            key={`row-${rowData.rowKey}`}
            rowMinHeight={rowMinHeight}
            ref={
              rowData.rowKey === rowInfoToHighlight?.rowKey
                ? rowToHighlightRef
                : undefined
            }
            onClick={handleRowClick}
            isSubRow={rowData.isSubRow}
            isClosed={isClosed}
            className={`${rowData.disabled ? "disabled" : ""} ${
              rowData.handleRowClick ? "clickable" : ""
            } ${rowData.rowClassName || ""} ${(() => {
              const isRowToHighlight =
                rowData.rowKey === rowInfoToHighlight?.rowKey &&
                isBlinkOnHighlightRow;

              if (isRowToHighlight) {
                return "blink";
              }

              return "";
            })()}`}
            isHighlighted={(() => {
              const isRowToHighlight =
                rowData.rowKey === rowInfoToHighlight?.rowKey;

              if (isRowToHighlight) {
                return true;
              }

              return false;
            })()}
            rowBackgroundColor={rowData.rowBackgroundColor}
            disabledRowHoverBgColor={disabledRowHoverBgColor}
          >
            {makeColumns(rowData)}
          </Styled.TableTr>
        );
      })}
    </>
  );
}
