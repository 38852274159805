import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { FULFILLMENT_BRIDGE_ATOMS } from "@sellernote/_shared/src/states/fulfillment/bridge";

/**
 * PDA의 스캔 결과를 사용한다.
 * *웹 브라우저에서 스캐너를 사용할때는 useBrowserScan을 사용해야한다.
 */
export default function useScan(handleScanResult: (result: string) => void) {
  const [scanResult, setScanResult] = useRecoilState(
    FULFILLMENT_BRIDGE_ATOMS.SCAN_RESULT
  );
  const setIsScanning = useSetRecoilState(FULFILLMENT_BRIDGE_ATOMS.IS_SCANNING);

  useEffect(() => {
    setIsScanning(true);

    return () => setIsScanning(false);
  }, [setIsScanning]);

  useEffect(() => {
    if (scanResult) {
      handleScanResult(scanResult);

      // 디버깅용 콘솔 출력
      console.log(`@@@스캔결과: ${scanResult}`);
    }

    setScanResult(undefined);
    // handleScanResult는 제외시킴
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanResult, setScanResult]);
}
