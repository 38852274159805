import { SCAN_RESULT_READ_FAIL } from "constants/scan";

import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  checkIsGroupedItem,
  getLabelCombinedWithSKUIdAndManagementDate,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForNormalItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";

import useScan from "hooks/common/useScan";
import useSelectDuplicateBarcode from "hooks/common/useSelectDuplicateBarcode";
import {
  getCounterKeyFromScanResult,
  getCounterKeyFromScanResultByItemIdInProgress,
  getIncompleteCounterInfoListFromScannedSkuBarcode,
  getIncompleteCounterKeyFromScannedSkuBarcode,
  InspectionCounterSKU,
  SKUCountingForInspection,
} from "hooks/returning/useSKUCountingForInspection";
import { returningActions } from "modules/returning";
import useUnverifiedItem from "pages/returning/inspection/:id/hooks/useUnverifiedItem";

export default function useScanInspectionSKU({
  skuCounting,
  returningId,
  setRowInfoToHighlight,
  startInspectionAt,
  registeredUnverifiedList,
  itemList,
  groupedItemIdInProgress,
  setGroupedItemIdInProgress,
  setSkuInProgress,
}: {
  skuCounting: SKUCountingForInspection;
  returningId: number;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
  startInspectionAt: string | undefined;
  registeredUnverifiedList?: ReceivingItem[];
  itemList: ReceivingItem[];
  groupedItemIdInProgress: number;
  setGroupedItemIdInProgress: (val: number) => void;
  setSkuInProgress: (val: InspectionCounterSKU | undefined) => void;
}) {
  const dispatch = useDispatch();

  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const {
    mutate: assignInspectorToItem,
    ResponseHandler: ResponseHandlerOfAssigningInspectorToItem,
  } = RETURNING_QUERY.useAssignInspectorToItem();

  const {
    mutate: setInspectionStarted,
    ResponseHandler: ResponseHandlerOfSettingInspectionStarted,
  } = RETURNING_QUERY.useSetInspectionStarted();

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const { handleSelectionSkuModalOpen, SelectSkuModal } =
    useSelectDuplicateBarcode<"returningInspection">({
      type: "returningInspection",
      itemList,
      setGroupedItemIdInProgress,
      setSkuInProgress,
    });

  const {
    addScannedUnverifiedItemToInspection,
    ResponseHandlerOfUpdateUnverifiedItem,
  } = useUnverifiedItem({
    returningId,
    registeredUnverifiedList,
    onSuccessForCreateUnverifiedItem: () => {
      dispatch(returningActions.GET_DETAIL({ id: returningId }));
    },
  });

  const handleScanResult = useCallback(
    (scanResult: string) => {
      if (!returningId || !currentManager) return;

      // 허공이나 손상된 바코드를 스캔하는 경우
      if (scanResult === SCAN_RESULT_READ_FAIL) {
        setValidationError({
          title: (
            <>
              정상 스캔이 되지 않았습니다.
              <br />
              다시 스캔하거나 직접 입력해 주세요.
            </>
          ),
        });

        return;
      }

      let scannedCounterKey: string | undefined;

      const itemListWithoutUnverifiedItem = itemList.filter(
        checkForNormalItemAsInspection
      );

      const isGroupedItemSelected = Boolean(groupedItemIdInProgress);
      console.log("isGroupedItemSelected", isGroupedItemSelected);

      /**
       * 상품바코드는 중복되는 경우가 있기 때문에 중복된 바코드를 선택하는 로직이 필요하다.
       * - 선택된 subRow가 있는 경우
       *   - 작업자의 작업 의도가 명확하기 때문에 해당 아이템을 기준으로 기존 로직이 동작
       * - 선택된 subRow가 없는 경우
       *   - 작업자의 의도를 알 수 없기 때문에 중복 바코드 선택 모달을 띄운다.
       */
      if (!isGroupedItemSelected) {
        const incompleteCounterInfoListFromScannedSkuBarcode =
          getIncompleteCounterInfoListFromScannedSkuBarcode({
            counterData: skuCounting,
            scanResult,
          });
        console.log(
          "incompleteCounterInfoListFromScannedSkuBarcode",
          incompleteCounterInfoListFromScannedSkuBarcode
        );

        /**
         * 새로운 검수 작업을 시작하는데, 검수완료되지 않은 중복된 바코드가 있는 경우
         * 작업자가 작업할 아이템을 선택하도록 한다.
         */
        if (
          !skuCounting.skuInProgress &&
          incompleteCounterInfoListFromScannedSkuBarcode.length > 1
        ) {
          // 아이템을 선택하는 경우 skuInProgress, groupedItemIdInProgress에 등록해서 이후 스캔부터는 해당 아이템이 스캔되도록 한다.
          handleSelectionSkuModalOpen(
            incompleteCounterInfoListFromScannedSkuBarcode
          );
          return;
        }

        /**
         * 검수완료되지 않은 중복된 바코드가 2개 이상 있는 경우
         * 선택 모달에서 등록한 skuInProgress를 기준으로 counterKey를 찾는다.
         */
        if (incompleteCounterInfoListFromScannedSkuBarcode.length > 1) {
          console.log("검수완료되지 않은 중복된 바코드가 2개 이상 있는 경우");
          scannedCounterKey = getCounterKeyFromScanResultByItemIdInProgress({
            counterData: skuCounting,
            scanResult,
            itemIdInProgress: skuCounting.skuInProgress?.itemId,
          });
          /**
           * 일반적인 미완료된(검수완료되지 않은 중복 상품 바코드가 1개 남은) 경우
           */
        } else if (
          incompleteCounterInfoListFromScannedSkuBarcode.length === 1
        ) {
          console.log(
            "일반적인 미완료된(검수완료되지 않은 중복 상품 바코드가 1개 남은) 경우"
          );
          scannedCounterKey = getIncompleteCounterKeyFromScannedSkuBarcode({
            counterData: skuCounting,
            scanResult,
          });
        }
      }

      // scannedCounterKey가 존재하는 경우 중복 바코드 관련 로직으로 스캔할 아이템(counterKey)가 결정된 상태이다.
      const hasTargetItemByDuplicateSkuList = Boolean(scannedCounterKey);

      scannedCounterKey = hasTargetItemByDuplicateSkuList
        ? scannedCounterKey
        : getCounterKeyFromScanResult({
            counterData: skuCounting,
            scanResult,
            itemIdInprogress: groupedItemIdInProgress,
          });

      const isScannedItemInList = !!itemListWithoutUnverifiedItem.some(
        (item) =>
          getFormattedSingleSkuId(item.sku.id) === scanResult ||
          item.sku.barCode === scanResult
      );
      // 관리일자가 지정된 아이템을 선택한 상태에서 리스트 내의 다른 아이템을 스캔한 경우
      if (
        isGroupedItemSelected &&
        isScannedItemInList &&
        /**
         * subRow를 선택한 상태에서는 groupedItemId를 기준으로 counterKey를 찾기 때문에
         * scannedCounterKey가 존재하지 않는다는 것은 선택한 아이템을 스캔하지 않았다는 의미이다.
         */
        !scannedCounterKey
      ) {
        const inProgressItem = itemListWithoutUnverifiedItem.find(
          (item) => item.id === groupedItemIdInProgress
        );

        setValidationError({
          title: `현재 선택한 상품(${getLabelCombinedWithSKUIdAndManagementDate(
            {
              SKUId: inProgressItem?.sku.id,
              managementKind: inProgressItem?.sku.managementKind,
              managementDate: inProgressItem?.managementDate,
            }
          )})의 바코드를 스캔해주세요.`,
        });
        return;
      }

      // 불일치의 경우 (등록된 SKU ID 나 바코드가 아닌 경우)
      if (!scannedCounterKey) {
        addScannedUnverifiedItemToInspection(scanResult);

        return;
      }

      const target = skuCounting.counter.counterInfo[scannedCounterKey];

      const isGroupedItem = checkIsGroupedItem(
        itemListWithoutUnverifiedItem,
        target?.skuId
      );
      if (isGroupedItem && !isGroupedItemSelected) {
        // 현재 '반품관리 > 검수'에서 grouped item은 관리일자가 있는 경우 밖에 없음
        setValidationError({
          title: "관리일자를 선택 후 스캔해주세요.",
        });
        return;
      }

      if (target.isCompleteInspection) {
        setValidationError({
          title: `이미 검수완료한 SKU ID 입니다.`,
        });
        return;
      }

      const otherSkuIsInProgress =
        !!skuCounting.skuInProgress &&
        target.counterKey !== skuCounting.skuInProgress.counterKey;
      if (otherSkuIsInProgress) {
        setValidationError({
          title: `현재 작업중인 검수(${getLabelCombinedWithSKUIdAndManagementDate(
            {
              SKUId: skuCounting.skuInProgress?.skuId,
              managementKind: skuCounting.skuInProgress?.managementKind,
              managementDate: skuCounting.skuInProgress?.managementDate,
            }
          )})를 완료한 후에 다른 검수를 진행할 수 있습니다.`,
        });
        return;
      }

      const isInitialScan = !target.inspectorId && !target.current;
      // TODO: 스캔시 담당자 권한 확인 해야함 - 창고의 네트워크 상황때문에 해당 단계 뺐음
      if (isInitialScan) {
        // 1) 작업자 지정 api 호출
        assignInspectorToItem(
          {
            pathParams: {
              returningId,
              itemId: target.itemId,
            },
          },
          {
            onSuccess: () => {
              // counterKey가 없는 경우 불일치 상품으로 취급되기 때문에 target이 없는 경우는 없다.
              if (!target) return;

              // 3) local count ++
              // 위에서 존재여부 이미 검사함
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              skuCounting.counter.addCountById(scannedCounterKey!);
              skuCounting.setSkuInProgress(target);
              setRowInfoToHighlight({
                rowKey: target.itemId,
              });

              dispatch(returningActions.GET_DETAIL({ id: returningId }));
            },
          }
        );
      } else {
        const isAssignedWorker = target.inspectorId === currentManager.id;
        if (!isAssignedWorker) {
          setValidationError({
            title: "이미 타 담당자가 해당 상품을 검수하고 있습니다.",
          });
          return;
        }

        skuCounting.counter.addCountById(scannedCounterKey);
        if (target.counterKey !== skuCounting.skuInProgress?.counterKey) {
          skuCounting.setSkuInProgress(target);
        }
        setRowInfoToHighlight({
          rowKey: target.itemId,
        });
      }
    },
    [
      returningId,
      currentManager,
      itemList,
      groupedItemIdInProgress,
      skuCounting,
      setValidationError,
      handleSelectionSkuModalOpen,
      addScannedUnverifiedItemToInspection,
      assignInspectorToItem,
      setRowInfoToHighlight,
      dispatch,
    ]
  );

  const checkIsInspectionStarted = useCallback(
    (scanResult: string) => {
      if (!startInspectionAt) {
        setInspectionStarted(
          {
            pathParams: { returningId },
          },
          {
            onSuccess: () => {
              handleScanResult(scanResult);
            },
          }
        );
        return;
      }

      handleScanResult(scanResult);
    },
    [startInspectionAt, handleScanResult, setInspectionStarted, returningId]
  );

  useScan(checkIsInspectionStarted);

  const ResultHandlerOfScanSKU = useMemo(
    () => (
      <>
        {ValidationErrorModal}

        {ResponseHandlerOfAssigningInspectorToItem}

        {ResponseHandlerOfSettingInspectionStarted}

        {ResponseHandlerOfUpdateUnverifiedItem}

        {SelectSkuModal}
      </>
    ),
    [
      ValidationErrorModal,
      ResponseHandlerOfAssigningInspectorToItem,
      ResponseHandlerOfSettingInspectionStarted,
      ResponseHandlerOfUpdateUnverifiedItem,
      SelectSkuModal,
    ]
  );

  return {
    ResultHandlerOfScanSKU,
  };
}
