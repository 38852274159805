import { useEffect, useMemo } from "react";
import { useCallback, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { noop } from "@sellernote/_shared/src/utils/common/etc";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import {
  checkUsesManagementDate,
  getLabelCombinedWithSKUIdAndManagementDate,
  getTeamLabelForBofulWorker,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";

import Layout from "containers/Layout";
import { LayoutRefreshInfo } from "containers/Layout/Refresh";
import withRequireAuth from "hocs/withRequireAuth";
import useConfirmModal from "hooks/common/useConfirmModal";
import useGetReceivingDetail from "hooks/receiving/useGetReceivingDetail";
import useScanInspectionSKU from "hooks/receiving/useScanInspectionSKU";
import useSKUCountingForInspection from "hooks/receiving/useSKUCountingForInspection";

import AddPersonInCharge from "./AddPersonInCharge";
import CloseInspection from "./CloseInspection";
import CompleteInspection from "./CompleteInspection";
import PersonInChargeList from "./PersonInChargeList";
import SKUList from "./SKUList";
import UnverifiedItemInput from "./UnverifiedItemInput";
import useUnverifiedList from "./useUnverifiedList";
import Styled from "./index.styles";

function ReceivingInspectionDetail() {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);

  const [canNotLeavePage, setCanNotLeavePage] = useRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );
  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const isCurrentManagerPersonInCharge = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_PERSON_IN_CHARGE
  );
  const isCurrentManagerReceivingManager = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_RECEIVING_MANAGER
  );
  const canCloseInspection = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.CAN_CLOSE_INSPECTION
  );
  const skuItems = useRecoilValue(FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS);

  // 검수 진행중인 itemId (관리일자 사용하는 SKU에서만 사용)
  const [groupedItemIdInProgress, setGroupedItemIdInProgress] = useState(0);

  const {
    data: receivingDetail,
    refetch: refetchReceivingDetail,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail,
  } = useGetReceivingDetail({ receivingId: Number(id) });

  const {
    registeredUnverifiedList,
    registeredUnverifiedCount,
    scannedUnregisteredUnverifiedCount,
  } = useUnverifiedList({ items: receivingDetail?.receiving.items });

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const skuCounting = useSKUCountingForInspection({
    items: skuItems,
    setRowInfoToHighlight,
    unverifiedCount:
      registeredUnverifiedCount + scannedUnregisteredUnverifiedCount,
    setGroupedItemIdInProgress,
  });

  const { ResultHandlerOfScanSKU } = useScanInspectionSKU({
    scanType: "single",
    skuCounting,
    receivingId: Number(id),
    setRowInfoToHighlight,
    startInspectionAt: receivingDetail?.receiving.startInspectionAt,
    registeredUnverifiedList,
    itemList: receivingDetail?.receiving.items || [],
    groupedItemIdInProgress,
    setGroupedItemIdInProgress,
    setSkuInProgress: skuCounting.setSkuInProgress,
  });

  // 검수가 시작된 후에는 파트타이머가 빠져나갈 수 없도록(뒤로가기 할 수 없도록) 함
  useEffect(() => {
    const currentUserIsPartTimer = currentManager?.authority === "partTimer";

    const canNotGoBackPosition =
      currentUserIsPartTimer && isCurrentManagerPersonInCharge;

    if (!canNotLeavePage && canNotGoBackPosition) {
      setCanNotLeavePage(true);
    }
  }, [
    canNotLeavePage,
    setCanNotLeavePage,
    currentManager?.authority,
    isCurrentManagerPersonInCharge,
  ]);

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  const checkUnfinishedInspectionOfMine = useCallback(
    (items: ReceivingItem[] | undefined) => {
      if (!items) return;

      return items.some((item) => {
        if (!item.inspectItems) return false;

        return item.inspectItems.some(
          (ii) =>
            ii.inspectorId === currentManager?.id && !ii.isCompleteInspecting
        );
      });
    },
    [currentManager?.id]
  );

  /**
   * 이번 검수완료 후 바로 다음 차례로 검수 할 itemId
   * - 상품 검수 완료 후, 다음으로 검수할 item의 위치로 자동 스크롤하는데 사용됨
   */
  const nextUnfinishedInspectionId = useMemo(() => {
    const receivingItems = receivingDetail?.receiving.items;

    const currentCountingSku = skuCounting.skuInProgress;

    const usesManagementDate = checkUsesManagementDate({
      managementKind: currentCountingSku?.managementKind,
      managementDate: currentCountingSku?.managementDate,
    });

    if (usesManagementDate) {
      // 관리일자를 사용하는 경우, 현재 스캔중인 Sku중 남은 item들 중 첫번째를 선택
      const unfinishedItemIdWithSameSku = receivingItems?.find((item) => {
        // 현재 카운팅중인 것은 제외함
        if (item.id === currentCountingSku?.itemId) return false;

        if (item.skuId !== currentCountingSku?.skuId) return false;

        return item.inspectItems.some((ii) => !ii.isCompleteInspecting);
      })?.id;
      if (unfinishedItemIdWithSameSku) return unfinishedItemIdWithSameSku;
    }

    // 관리일자를 사용하지 않거나, 현재 스캔중인 sku중 남은 item이 없다면 전체 item을 대상으로 확인
    return receivingItems?.find((item) => {
      // 현재 카운팅중인 것은 제외함
      if (item.id === currentCountingSku?.itemId) return false;

      return item.inspectItems.some((ii) => !ii.isCompleteInspecting);
    })?.id;
  }, [receivingDetail?.receiving.items, skuCounting.skuInProgress]);

  /**
   * 담당중인 SKU 중 미결된 것이 있으면 이탈 할 수 없게 함
   */
  useEffect(() => {
    const hasUnfinishedInspectionOfMine = checkUnfinishedInspectionOfMine(
      receivingDetail?.receiving?.items
    );

    if (hasUnfinishedInspectionOfMine) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [
    checkUnfinishedInspectionOfMine,
    receivingDetail?.receiving?.items,
    setCanNotLeavePage,
  ]);

  const refreshInfo: LayoutRefreshInfo = useMemo(() => {
    let confirmMessage = "";

    if (skuCounting.skuInProgress) {
      confirmMessage = `현재 카운트 중인 SKU ID(${getLabelCombinedWithSKUIdAndManagementDate(
        {
          SKUId: skuCounting.skuInProgress.skuId,
          managementKind: skuCounting.skuInProgress.managementKind,
          managementDate: skuCounting.skuInProgress.managementDate,
        }
      )})가 초기화 됩니다.`;
    }

    return {
      ...(confirmMessage ? { confirm: { message: confirmMessage } } : {}),
      handleRefresh: async (showSuccessMessage) => {
        const { data } = await refetchReceivingDetail({ throwOnError: true });

        if (!data) {
          return;
        }

        setRowInfoToHighlight(undefined);
        showSuccessMessage();
        skuCounting.reset(data.data.receiving.items);
      },
    };
  }, [skuCounting, refetchReceivingDetail]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);

      // 초기화하면 초기화한 SKU작업이 진행중인 작업이 된다.
      const target = skuCounting.counter.counterInfo[counterKey];
      skuCounting.setSkuInProgress(target);
      setRowInfoToHighlight({ rowKey: target.itemId });
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterRemoveInProgressPersonInCharge = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      skuCounting.setSkuInProgress(undefined);
      setRowInfoToHighlight(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  console.log("groupedItemIdInProgress", groupedItemIdInProgress);

  const resetAfterComplete = useCallback(() => {
    console.log("resetAfterComplete");
    setRowInfoToHighlight(
      nextUnfinishedInspectionId
        ? { rowKey: nextUnfinishedInspectionId }
        : undefined
    );
    skuCounting.setSkuInProgress(undefined);
    setGroupedItemIdInProgress(0);
  }, [nextUnfinishedInspectionId, skuCounting]);

  const counterInProgress = skuCounting.skuInProgress
    ? skuCounting.counter.counterInfo[skuCounting.skuInProgress.counterKey]
    : undefined;

  return (
    <Layout
      navigator={{
        title: `${getFormattedReceivingId(
          receivingDetail?.receiving
        )} / ${getTeamLabelForBofulWorker({
          id: receivingDetail?.receiving.team?.id,
          name: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.name,
            maxLength: 10,
            ellipsis: "...",
          }),
          company: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.company,
            maxLength: 10,
            ellipsis: "...",
          }),
        })}`,
      }}
      refreshInfo={refreshInfo}
    >
      <Styled.container>
        {ConfirmModal}

        <div className="header">
          <AddPersonInCharge
            isManager={isCurrentManagerReceivingManager}
            workerList={receivingDetail?.receiving.workerList}
          />

          <Button
            label="상품스캔"
            size="small"
            theme="tertiary"
            handleClick={noop}
          />
        </div>

        <PersonInChargeList
          receivingManagerId={receivingDetail?.receiving.managerId}
          workerList={receivingDetail?.receiving.workerList}
        />

        <UnverifiedItemInput
          registeredUnverifiedList={registeredUnverifiedList}
          receivingId={Number(id)}
          counterInProgress={counterInProgress}
        />

        <SKUList
          receivingId={Number(id)}
          receivingItems={receivingDetail?.receiving.items}
          rowInfoToHighlight={rowInfoToHighlight}
          setRowInfoToHighlight={setRowInfoToHighlight}
          skuCounting={skuCounting}
          resetAfterRemoveInProgressPersonInCharge={
            resetAfterRemoveInProgressPersonInCharge
          }
          resetCountByCounterKey={resetCountByCounterKey}
          setConfirmModal={setConfirmModal}
          itemIdInProgress={groupedItemIdInProgress}
          setItemIdInProgress={setGroupedItemIdInProgress}
        />

        <div className="total-count">
          <span className="label">총 카운트:</span>
          <span className="value">{skuCounting.totalCount}</span>
        </div>

        <div className="footer">
          {canCloseInspection ? (
            <CloseInspection receivingId={Number(id)} />
          ) : (
            <CompleteInspection
              locationType="single"
              receivingId={Number(id)}
              counterInProgress={counterInProgress}
              resetAfterComplete={resetAfterComplete}
            />
          )}
        </div>
      </Styled.container>

      {ResponseHandlerOfGettingManagerReceivingDetail}
      {ResultHandlerOfScanSKU}
    </Layout>
  );
}

export default withRequireAuth(ReceivingInspectionDetail);
