import React, { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import {
  BofulLocation,
  WarehousingStatusLabel,
} from "@sellernote/_shared/src/types/fulfillment/common";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { ReceivingPlaceItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { ScannedLocation } from "@sellernote/_shared/src/types/fulfillment/scan";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";
import { changeScanModeToLocationOnFullCount } from "@sellernote/_shared/src/utils/fulfillment/common";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useRemoveWarehousingPersonInCharge from "hooks/receiving/useRemoveWarehousingPersonInCharge";
import useResetWarehousingOfItem from "hooks/receiving/useResetWarehousingOfItem";
import {
  CounterDataForWarehousing,
  getCounterKeyForMultiLocationWarehousing,
  SKUCountingForWarehousing,
  WarehousingCounterSKU,
} from "hooks/receiving/useSKUCountingForWarehousing";

import CountForScanning from "components/CountForScanning";
import PersonInCharge from "components/PersonInCharge";
import RemovePartialItem from "components/RemovePartialItem";
import StyledForRemove from "components/RemovePartialItem/index.styles";
import ResetCountingAsPartial from "components/ResetCountingAsPartial";
import StyledForReset from "components/ResetCountingAsPartial/index.styles";

import { ScanModeForReceivingWarehousing } from "../../../Warehousing";
import useRemovePartialWarehousing from "./useRemovePartialWarehousing";

interface WarehousingPartedListTableItem {
  selection: React.ReactNode;
  location: string;
  count: React.ReactNode;
  personInCharge?: React.ReactNode;
  status: "Y" | "N";
  reset: React.ReactNode;
  remove: React.ReactNode;
}

function getLocationName({
  placeItem,
  counter,
  locationListOfWarehouse,
  selectedLocation,
  placingIdInProgress,
}: {
  placeItem: ReceivingPlaceItem;
  counter: UseCounterDataValue<WarehousingCounterSKU> | undefined;
  locationListOfWarehouse: BofulLocation[];
  selectedLocation: ScannedLocation | undefined;
  placingIdInProgress: string | undefined;
}) {
  if (
    placingIdInProgress &&
    placingIdInProgress === placeItem.placingId &&
    counter?.current
  ) {
    return selectedLocation?.barCode ?? "";
  }

  if (placeItem.locationId) {
    return (
      locationListOfWarehouse.find((v) => v.id === placeItem.locationId)
        ?.barCode ?? ""
    );
  }

  return "";
}

export default function PartedList({
  setScanMode,
  receivingId,
  itemId,
  skuId,
  managementKind,
  managementDate,
  placeItems,
  selectedLocation,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  placingIdInProgress,
  setPlacingIdInProgress,
  skuCounting,
  counterData,
  maxCount,
  addCountByInput,
  resetCountByCounterKey,
  setConfirmModal,
  statusLabel,
  isUnverifiedSku,
}: {
  setScanMode: (v: ScanModeForReceivingWarehousing) => void;
  receivingId: number;
  itemId: number;
  skuId: number;
  managementKind?: ManagementKind;
  managementDate?: string;
  placeItems: ReceivingPlaceItem[];
  selectedLocation?: ScannedLocation;
  rowInfoToHighlight?: TableRowInfoToHighlight;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
  placingIdInProgress?: string;
  setPlacingIdInProgress: (val: string) => void;
  skuCounting: SKUCountingForWarehousing;
  counterData: CounterDataForWarehousing;
  maxCount: number;
  addCountByInput: ({ id, count }: { id: string; count: number }) => void;
  resetCountByCounterKey: (counterKey: string) => () => void;
  setConfirmModal: React.Dispatch<
    React.SetStateAction<ConfirmModal | undefined>
  >;
  statusLabel: WarehousingStatusLabel | undefined;
  isUnverifiedSku: boolean;
}) {
  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const isCurrentManagerReceivingManager = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_RECEIVING_MANAGER
  );
  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const getWorkerById = useGetWorkerByIdFactory();

  const { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge } =
    useRemoveWarehousingPersonInCharge();

  const {
    resetWarehousingOfItem,
    ResponseHandlerOfResetWarehousingOfItemExceptPlacer,
  } = useResetWarehousingOfItem();

  const {
    removePartialWarehousingFromItem,
    ResponseHandlerOfRemovingPartialWarehousingFromItem,
  } = useRemovePartialWarehousing({ receivingId });

  const handleCountAddByDirectInput = useCallback(
    (counterKey: string) => (count: number | undefined) => {
      if (!count) return;

      const target = skuCounting.counter.counterInfo[counterKey];

      addCountByInput({ id: counterKey, count });

      setRowInfoToHighlight({ rowKey: target.placingId });

      changeScanModeToLocationOnFullCount({
        setScanMode,
        count,
        max: target.max,
      });
    },
    [
      addCountByInput,
      setRowInfoToHighlight,
      setScanMode,
      skuCounting.counter.counterInfo,
    ]
  );

  const tableDataList = useMemo(() => {
    const isLastRemaining = placeItems.length === 1;

    return placeItems.map((v) => {
      const isSelected = v.placingId === placingIdInProgress;

      const completedWarehousing = v.isCompletePlacing;

      const counterKey = getCounterKeyForMultiLocationWarehousing(
        skuId,
        v.placingId
      );
      const counter = counterData[counterKey];

      const currentUserIsMultiLocationWarehousingAssignee =
        v.placerId === currentManager?.id;

      const currentCount = counter?.current || v.placeQty || 0;

      const skuInprogress =
        v.placingId === skuCounting.skuInProgress?.placingId;

      const canOpenDirectInputModal =
        isSelected &&
        currentUserIsMultiLocationWarehousingAssignee &&
        !completedWarehousing &&
        skuInprogress;

      const canReset = v.isCompletePlacing || !!counter?.current;

      const isNowCounting =
        isSelected && !completedWarehousing && !!counter?.current;

      const mapped: TableDataListItem<WarehousingPartedListTableItem> = {
        rowKey: v.placingId,

        selection: isSelected ? (
          <Icon type="radioSelected" size={1} color={COLOR.primaryBlue} />
        ) : completedWarehousing ? (
          <Icon
            type="checkStrokeCircleDisabled"
            size={1}
            color={COLOR.grayScale_300}
          />
        ) : (
          <Icon
            type="radio"
            size={1}
            color={COLOR.grayScale_300}
            onClick={() => setPlacingIdInProgress(v.placingId)}
          />
        ),

        location: getLocationName({
          placeItem: v,
          counter,
          locationListOfWarehouse,
          selectedLocation,
          placingIdInProgress,
        }),

        count: (
          <CountForScanning
            // type="warehousing"
            canOpenDirectInputModal={canOpenDirectInputModal}
            // skuId={skuId}
            currentQty={currentCount}
            quantity={v.quantity}
            // onCountAdd={handleCountAddByDirectInput(counterKey)}
            openDirectInputModal={() => {
              handleDirectInputModalOpen();
              setCountForScanning({
                type: "receivingWarehousing",
                skuId,
                currentQty: currentCount,
                quantity: v.quantity,
                onCountAdd: handleCountAddByDirectInput(counterKey),
                statusLabel,
                isUnverifiedSku,
                managementDate,
                managementKind,
              });
            }}
          />
        ),

        personInCharge: (
          <PersonInCharge
            worker={getWorkerById(v.placerId)?.name}
            isRemovable={isCurrentManagerReceivingManager}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: "SKU 담당자를 삭제하시겠습니까?",
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: removePersonInCharge({
                      receivingId,
                      itemId,
                      placingId: v.placingId,
                      resetAfterRemoveInProgressPersonInCharge:
                        resetCountByCounterKey(counterKey),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // removePersonInCharge={removePersonInCharge({
            //   receivingId,
            //   itemId,
            //   placingId: v.placingId,
            //   resetAfterRemoveInProgressPersonInCharge:
            //     resetCountByCounterKey(counterKey),
            // })}
          />
        ),

        status: completedWarehousing ? "Y" : "N",

        reset: (
          <ResetCountingAsPartial
            type="warehousing"
            canReset={canReset}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: (
                  <StyledForReset.resetConfirmModalTitle>
                    선택한 항목의 분할입고를 <b>초기화</b>하겠습니까?
                  </StyledForReset.resetConfirmModalTitle>
                ),
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: resetWarehousingOfItem({
                      receivingId,
                      itemId,
                      placingId: v.placingId,
                      resetLocalCount: resetCountByCounterKey(counterKey),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // reset={resetWarehousingOfItem({
            //   receivingId,
            //   itemId,
            //   placingId: v.placingId,
            //   resetLocalCount: resetCountByCounterKey(counterKey),
            // })}
          />
        ),

        remove: (
          <RemovePartialItem
            type="warehousing"
            isNowCounting={isNowCounting}
            openConfirmModal={() => {
              isNowCounting
                ? setConfirmModal({
                    uiType: "titleOnly",
                    title: (
                      <>
                        현재 작업 중인 항목은 삭제할 수 없습니다 <br /> (초기화
                        후 진행 가능)
                      </>
                    ),
                    actions: {
                      actionPositive: {
                        label: "확인",
                        handleClick: () => setConfirmModal(undefined),
                      },
                    },
                  })
                : setConfirmModal({
                    uiType: "titleOnly",
                    title: (
                      <StyledForRemove.removeConfirmModalTitle>
                        선택한 항목의 분할입고를 <b>삭제</b>
                        하겠습니까?
                      </StyledForRemove.removeConfirmModalTitle>
                    ),
                    actions: {
                      actionPositive: {
                        label: "예",
                        handleClick: removePartialWarehousingFromItem({
                          receivingId,
                          itemId,
                          placingId: v.placingId,
                          isLastRemaining,
                          maxCount,
                          postSuccessCallback: () => {
                            setPlacingIdInProgress("");
                            setConfirmModal(undefined);
                          },
                        }),
                      },
                      actionNegative: {
                        label: "아니오",
                        handleClick: () => setConfirmModal(undefined),
                      },
                    },
                  });
            }}
            // remove={removePartialWarehousing({
            //   receivingId,
            //   itemId,
            //   placingId: v.placingId,
            //   isLastRemaining,
            //   skuId,
            //   maxCount,
            //   setPlacingIdInProgress,
            // })}
          />
        ),
      };

      return mapped;
    });
  }, [
    placeItems,
    placingIdInProgress,
    skuId,
    counterData,
    currentManager?.id,
    skuCounting.skuInProgress?.placingId,
    locationListOfWarehouse,
    selectedLocation,
    getWorkerById,
    isCurrentManagerReceivingManager,
    setPlacingIdInProgress,
    handleDirectInputModalOpen,
    setCountForScanning,
    handleCountAddByDirectInput,
    statusLabel,
    isUnverifiedSku,
    managementDate,
    managementKind,
    setConfirmModal,
    removePersonInCharge,
    receivingId,
    itemId,
    resetCountByCounterKey,
    resetWarehousingOfItem,
    removePartialWarehousingFromItem,
    maxCount,
  ]);

  return (
    <>
      <TableForCounting<WarehousingPartedListTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          selection: {
            label: "선택",
            fixedWidth: 32,
          },
          location: {
            label: "위치",
            fixedWidth: 80,
          },
          count: {
            label: "카운트",
            fixedWidth: 84,
          },
          personInCharge: {
            label: "담당자",
            fixedWidth: 110,
          },
          status: {
            label: (
              <>
                입고완료
                <br />
                여부
              </>
            ),
            fixedWidth: 58,
          },
          reset: {
            label: "초기화",
            fixedWidth: 74,
          },
          remove: {
            label: "삭제",
            fixedWidth: 74,
          },
        }}
        dataList={tableDataList}
      />

      {CountForScanningModal}

      {ResponseHandlerOfRemovingPersonInCharge}
      {ResponseHandlerOfResetWarehousingOfItemExceptPlacer}
      {ResponseHandlerOfRemovingPartialWarehousingFromItem}
    </>
  );
}
