import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ReactWindowList from "@sellernote/_shared/src/components/ReactWindowList";
import {
  RowToHighlightRef,
  TableProps,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";

import Styled from "../index.styles";
import TableBodyRow from "./TableBodyRow";

/**
 * <참고>
 * - `TableProps`중 필요한 부분믄 Pick하고 싶었으나, Pick이나 Omit을 사용하면 `propsByType` 같은 타입 추론이 안 되어서 불가피하게 `TableProps`타입 전체를 사용함.
 */
type TableBodyProps<T> = TableProps<T> & {
  rowToHighlightRef: RowToHighlightRef;
  tableRef: React.MutableRefObject<HTMLTableElement>;
  isBlinkOnHighlightRow: boolean;
  isOverflowed: boolean;
};

function TableBody<T>({
  dataList,
  columnInfo,
  labelForNoData,
  disabledRowHoverBgColor,
  rowMinHeight,
  tableRef,
  rowInfoToHighlight,
  isBlinkOnHighlightRow,
  isOverflowed,
  rowToHighlightRef,
  ...propsByType
}: TableBodyProps<T>) {
  const { t } = useTranslation("sds-v2");

  const RowsForNotIsWindowed = useMemo(() => {
    if (propsByType.isWindowed) return null;

    return dataList?.map((row) => {
      return (
        <TableBodyRow
          key={row.rowKey}
          rowMinHeight={rowMinHeight}
          rowInfoToHighlight={rowInfoToHighlight}
          rowToHighlightRef={rowToHighlightRef}
          isBlinkOnHighlightRow={isBlinkOnHighlightRow}
          disabledRowHoverBgColor={
            disabledRowHoverBgColor || row.disabledRowHoverBgColor
          }
          isOverflowed={isOverflowed}
          tableRef={tableRef}
          columnInfo={columnInfo}
          rowData={row}
        />
      );
    });
  }, [
    propsByType.isWindowed,
    dataList,
    rowMinHeight,
    rowInfoToHighlight,
    rowToHighlightRef,
    isBlinkOnHighlightRow,
    disabledRowHoverBgColor,
    isOverflowed,
    tableRef,
    columnInfo,
  ]);

  const RowsForIsWindowed = useMemo(() => {
    if (!propsByType.isWindowed) return null;

    return (
      <ReactWindowList
        height={propsByType.height}
        scrollWidth={tableRef.current?.scrollWidth}
        dataList={dataList}
        rowKeyToHighlight={rowInfoToHighlight?.rowKey}
        Row={({ data, index }) => (
          <TableBodyRow
            rowMinHeight={rowMinHeight}
            rowInfoToHighlight={rowInfoToHighlight}
            rowToHighlightRef={rowToHighlightRef}
            isBlinkOnHighlightRow={isBlinkOnHighlightRow}
            disabledRowHoverBgColor={
              disabledRowHoverBgColor || data[index].disabledRowHoverBgColor
            }
            isOverflowed={isOverflowed}
            tableRef={tableRef}
            columnInfo={columnInfo}
            rowData={data[index]}
          />
        )}
      />
    );
  }, [
    columnInfo,
    dataList,
    disabledRowHoverBgColor,
    isBlinkOnHighlightRow,
    isOverflowed,
    propsByType,
    rowInfoToHighlight,
    rowMinHeight,
    rowToHighlightRef,
    tableRef,
  ]);

  return !dataList?.length ? (
    <>
      {!dataList?.length && !labelForNoData && (
        <Styled.noData>{t("sds-v2:Table_데이터_없음")}</Styled.noData>
      )}

      {!dataList?.length && labelForNoData && (
        <>
          {typeof labelForNoData === "string" ? (
            <Styled.noData>{labelForNoData}</Styled.noData>
          ) : (
            labelForNoData
          )}
        </>
      )}
    </>
  ) : (
    <tbody>
      {propsByType.isWindowed ? RowsForIsWindowed : RowsForNotIsWindowed}
    </tbody>
  );
}

export type { TableBodyProps };

export default TableBody;
