import { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { Placement } from "@popperjs/core";
import { enUS, ko } from "date-fns/locale";
import dayjs from "dayjs";

import "react-datepicker/dist/react-datepicker.css";

import { APP_CURRENT_LANGUAGE } from "@sellernote/_shared/src/constants";
import {
  TODAY,
  TOMORROW,
} from "@sellernote/_shared/src/utils/common/constants";
import {
  addDate,
  isBusinessDay,
  isHoliday,
  isSameDay,
} from "@sellernote/_shared/src/utils/common/date";

import AlertModal from "./AlertModal";
import CustomHeader from "./CustomHeader";
import CustomInput from "./CustomInput";
import TimePicker, { getTimeToReset } from "./TimePicker";
import Styled from "./index.styles";

type CalendarSize = "default" | "small";

type SelectedTime = {
  hour: number | null;
  minute: number | null;
};

type DatePickerType = {
  type: "datePicker";
};

type DateTimePickerType = {
  type: "dateTimePicker";
  selectableTime?: SelectableTime;
};

type SelectableTime = {
  startHour: number;
  endHour: number;
  disabledHourList?: number[];
};

type MinDateOption = "today" | "tomorrow" | Date;

export type { CalendarSize, SelectedTime, SelectableTime, MinDateOption };

const isSunday = (date: Date) => date.getDay() === 0;

const getDayClassName = (date: Date) => {
  if (isSunday(date)) {
    return "sunday";
  }

  if (isHoliday(date)) {
    return "holiday";
  }

  return "";
};

const geMinDateOption = (minDate: MinDateOption | undefined) => {
  if (minDate === "today") {
    return TODAY;
  }

  if (minDate === "tomorrow") {
    return TOMORROW;
  }

  return minDate;
};

const getMaxDate = (
  minDate: Date | undefined,
  dateRangeOffset: number | undefined
) => {
  if (!dateRangeOffset || !minDate) {
    return;
  }

  const maxDate = addDate({
    date: minDate,
    unit: "day",
    value: dateRangeOffset,
  });

  return maxDate;
};

const convertToUnicodeTokens = (format: string) => {
  // 참고) https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
  return format.replace(/[YD]/g, (match) => match.toLowerCase());
};

const checkIsDisabledDate = (date: Date, disabledDateList: (string | Date)[]) =>
  !disabledDateList.some((disabledDate) => isSameDay(date, disabledDate));

/**
 * 기존에 개발된 InputDatePicker가 있기 때문에 Calendar 컴포넌트를 별도로 개발함
 * 추후에 InputDatePicker를 Calendar로 대체할 예정
 */
export default function Calendar({
  size = "default",
  placeholder,
  labelInfo,
  date,
  setDate,
  allowsLocalReset = false,
  isDisabled,
  inputWidth,
  getMinDate,
  dateRangeOffset,
  holidayOption,
  calendarPosition = "bottom-start",
  disabledDateList,
  fixedHeight = false,
  getOpenToDate,
  className,
  ...propsByType
}: {
  /**
   * @default default
   */
  size?: CalendarSize;
  placeholder?: string;
  labelInfo: {
    /**
     * WCAG 준수 차원에서 드러내지 않더라도 필수로 입력
     * UI로 드러내지 않는 경우는 isLabelHidden속성을 사용
     */
    label: string;
    isLabelHidden?: boolean;
  };
  /**
   * dayjs로 변환 가능한 날짜 형식
   */
  date: string;
  setDate: (val: string) => void;
  /** 컴포넌트 내 리셋 버튼으로 초기화가 필요한 경우 사용*/
  allowsLocalReset?: boolean;
  isDisabled?: boolean;
  inputWidth?: string;
  /**
   * MinDateOption: "today" | "tomorrow" | Date
   * 캘린더가 열리는 시점에 minDate를 다시 계산하기 위함 (컴포넌트 마운트 시점과 캘린더가 열리는 시점의 차이로 인해 발생하는 이슈 방지)
   */
  getMinDate?: () => MinDateOption | undefined;
  /**
   * minDate에서 해당 일자만큼을 더한 날짜를 maxDate로 설정
   */
  dateRangeOffset?: number;
  holidayOption?:
    | {
        type: "deactivation";
      }
    | {
        type: "modal";
        message: string;
      };
  /**
   * input 기준으로 캘린더의 위치
   * @default bottom-start
   */
  calendarPosition?: Extract<
    Placement,
    "bottom-start" | "bottom-end" | "top-start"
  >;
  /**
   * 선택 불가능한 일자 리스트
   */
  disabledDateList?: (string | Date)[];
  /**
   * 캘린더의 높이를 고정
   */
  fixedHeight?: boolean;
  /**
   * 캘린더 오픈시 사용하는 기준 날짜
   * - 캘린더가 열리는 시점에 minDate를 다시 계산하기 위함 (컴포넌트 마운트 시점과 캘린더가 열리는 시점의 차이로 인해 발생하는 이슈 방지)
   */
  getOpenToDate?: () => string | Date;
  className?: string;
} & (DatePickerType | DateTimePickerType)) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<SelectedTime>({
    hour: null,
    minute: null,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [showsAlertModal, setShowsAlertModal] = useState(false);

  const minDateOption = isEditMode
    ? geMinDateOption(getMinDate?.())
    : undefined;

  const maxDateOption = isEditMode
    ? getMaxDate(minDateOption, dateRangeOffset)
    : undefined;

  const openToDate = isEditMode
    ? (() => {
        if (!getOpenToDate) return;

        const date = getOpenToDate();
        if (!date) return;

        if (typeof date === "string") {
          return new Date(date);
        }

        return date;
      })()
    : undefined;

  const datePickerRef = useRef<DatePicker>(null);

  const isDateTimePicker = propsByType.type === "dateTimePicker";

  const placeholderText =
    placeholder ||
    (propsByType.type === "datePicker" ? "YY-MM-DD" : "YY-MM-DD 00:00");

  const dateFormat = isDateTimePicker ? "YY-MM-DD HH:mm" : "YY-MM-DD";

  const handleAlertModalOpen = () => {
    setShowsAlertModal(true);
  };

  const handleAlertModalClose = () => {
    setShowsAlertModal(false);
  };

  const handleDateSelect = (selectedDate: Date | null) => {
    if (!selectedDate) {
      return;
    }

    /**
     * 휴무일을 선택했을 때 알림 모달을 띄우고 값을 적용하지 않음
     * 참고) https://www.notion.so/shipda/5f1d3e69f53443f28a82fff03bebecc3
     */
    if (holidayOption?.type === "modal" && !isBusinessDay(selectedDate)) {
      handleAlertModalOpen();
      return;
    }

    setSelectedDate(selectedDate);

    /**
     * DatePicker인 경우 선택한 날짜를 바로 적용 (시간은 0시로 설정)
     */
    if (!isDateTimePicker) {
      setDate(dayjs(selectedDate).startOf("day").toISOString());
      datePickerRef.current?.setOpen(false);
    }

    const isMinDateToday = getMinDate?.() === "today";
    const isTodaySelected = isSameDay(selectedDate, TODAY);
    const needsToResetTime =
      isDateTimePicker && isMinDateToday && isTodaySelected;
    if (needsToResetTime) {
      const getTimeToResetByType = getTimeToReset({
        selectedDate,
        selectedTime,
        selectableTime: propsByType.selectableTime,
        minDate: getMinDate?.(),
      });
      const getHourToReset = getTimeToResetByType("hour");
      const getMinuteToReset = getTimeToResetByType("minute");

      setSelectedTime((prev) => ({
        ...prev,
        hour: getHourToReset(prev.hour),
        minute: getMinuteToReset(prev.minute),
      }));
    }
  };

  const filterDate = (date: Date) =>
    (() => {
      const isHolidayDisabled = holidayOption?.type === "deactivation";
      const hasDisabledDateList = disabledDateList?.length;

      const isBusinessDayResult = isHolidayDisabled
        ? isBusinessDay(date)
        : true;

      const isDisabledDateResult = hasDisabledDateList
        ? checkIsDisabledDate(date, disabledDateList)
        : true;

      return isBusinessDayResult && isDisabledDateResult;
    })();

  const handleCalendarOpen = () => {
    setIsEditMode(true);

    if (!date) {
      return;
    }

    try {
      const formattedDate = dayjs(date).toDate();

      setSelectedDate(formattedDate);
      setSelectedTime({
        hour: formattedDate.getHours(),
        minute: formattedDate.getMinutes(),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCalendarClose = () => {
    setIsEditMode(false);
  };

  const handleCalendarReset = () => {
    setDate("");
  };

  const handleOutsideClick = () => {
    /**
     * DatePicker인 경우 '선택완료'를 누르지 않고 외부를 클릭하면 선택한 값을 적용하지 않음
     */
    if (isDateTimePicker) {
      const hasPrevDate = !!date;
      if (hasPrevDate) {
        const formattedDate = new Date(date);

        setSelectedDate(formattedDate);
        setSelectedTime({
          hour: formattedDate.getHours(),
          minute: formattedDate.getMinutes(),
        });
        return;
      }

      setSelectedDate(null);
      setSelectedTime({ hour: null, minute: null });
    }
  };

  return (
    <>
      <Styled.calendarWrapper
        className={`${className ? className : ""} calendar`}
      >
        <DatePicker
          fixedHeight={fixedHeight}
          locale={(() => {
            if (APP_CURRENT_LANGUAGE === "en") {
              // enSG는 없어서 enUS를 사용
              return enUS;
            }

            return ko;
          })()}
          ref={datePickerRef}
          dayClassName={getDayClassName}
          calendarContainer={({
            children,
          }: {
            children: React.ReactNode[];
          }) => (
            <Styled.container>
              {children}

              {isDateTimePicker && (
                <TimePicker
                  datePickerRef={datePickerRef}
                  prevDate={date}
                  setDate={setDate}
                  selectedDate={selectedDate}
                  selectedTime={selectedTime}
                  setSelectedTime={setSelectedTime}
                  minDate={getMinDate?.()}
                  selectableTime={propsByType.selectableTime}
                />
              )}
            </Styled.container>
          )}
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
            <CustomHeader
              date={date}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
            />
          )}
          customInput={
            <CustomInput
              date={date}
              size={size}
              isEditMode={isEditMode}
              isDisabled={isDisabled}
              labelInfo={labelInfo}
              inputWidth={inputWidth}
              dateFormat={dateFormat}
              allowsLocalReset={allowsLocalReset}
              onCalendarReset={handleCalendarReset}
            />
          }
          selected={selectedDate}
          onChange={handleDateSelect}
          onCalendarOpen={handleCalendarOpen}
          onCalendarClose={handleCalendarClose}
          dateFormat={convertToUnicodeTokens(dateFormat)}
          filterDate={filterDate}
          placeholderText={placeholderText}
          minDate={minDateOption}
          maxDate={maxDateOption}
          shouldCloseOnSelect={false}
          disabled={isDisabled}
          disabledKeyboardNavigation
          onClickOutside={handleOutsideClick}
          popperPlacement={calendarPosition}
          popperProps={{ strategy: "fixed" }}
          openToDate={openToDate}
        />
      </Styled.calendarWrapper>

      {holidayOption?.type === "modal" && (
        <AlertModal
          showsAlertModal={showsAlertModal}
          onAlertModalClose={handleAlertModalClose}
          message={holidayOption.message}
        />
      )}
    </>
  );
}
