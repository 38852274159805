import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section`
  > .header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .left {
      ${setFontStyle("SubHead2", "Bold")};
      color: ${COLOR.grayScale_800};
    }

    > .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > .button {
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  > .main-manager {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    > .main-manager-label {
      ${setFontStyle("SubHead3", "Bold")};
      color: ${COLOR.grayScale_700};
    }

    // 불필요한 마진으로 인해 레이아웃 깨지는 현상 수정
    > .custom-chip-with-clear {
      .label {
        margin-bottom: 0;
      }
    }
  }

  > .total-count {
    margin-top: 40px;
    padding: 8px;
    border-radius: 4px;
    background-color: ${COLOR.bgColor_3};
    text-align: center;

    > .label {
      ${setFontStyle("SubHead2")};
      margin-right: 8px;
    }

    > .value {
      ${setFontStyle("Body6", "Bold")};
    }
  }

  > .footer {
    margin-top: 40px;

    > .button {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
`;

export default {
  container,
};
