import { SCAN_RESULT_READ_FAIL } from "constants/scan";

import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { noop } from "@sellernote/_shared/src/utils/common/etc";
import {
  checkIsGroupedItem,
  getLabelCombinedWithSKUIdAndManagementDate,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForNormalItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";

import useScan from "hooks/common/useScan";
import useSelectDuplicateBarcode from "hooks/common/useSelectDuplicateBarcode";
import {
  checkIsMultiLocationItem,
  getCounterKeyFromScanResultByInspectingIdInProgress,
  getIncompleteSingleLocationCounterInfoListFromScannedSkuBarcode,
  getIncompleteSingleLocationCounterKeyFromScannedSkuBarcode,
  getSingleLocationCounterKeyFromScanResult,
  InspectionCounterSKU,
  SKUCountingForInspection,
} from "hooks/receiving/useSKUCountingForInspection";
import useUnverifiedItem from "pages/receiving/inspection/:id/hooks/useUnverifiedItem";

/**
 * 일반검수/분할검수를 구분
 */
type ScanTypeInfo =
  | {
      scanType: "single";
      itemList: ReceivingItem[];
      groupedItemIdInProgress: number;
      setGroupedItemIdInProgress: (val: number) => void;
      setSkuInProgress: (val: InspectionCounterSKU | undefined) => void;
    }
  | { scanType: "multi"; itemId: number; inspectingId?: string };

export default function useScanInspectionSKU({
  skuCounting,
  receivingId,
  setRowInfoToHighlight,
  startInspectionAt,
  registeredUnverifiedList,
  ...scanTypeInfo
}: ScanTypeInfo & {
  skuCounting: SKUCountingForInspection;
  receivingId: number;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
  startInspectionAt: string | undefined;
  registeredUnverifiedList?: ReceivingItem[];
}) {
  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const queryClient = useQueryClient();

  const {
    mutate: assignInspectorToItem,
    ResponseHandler: ResponseHandlerOfAssigningInspectorToItem,
  } = RECEIVING_QUERY.useAssignInspectorToItem();

  const {
    mutate: setInspectionStarted,
    ResponseHandler: ResponseHandlerOfSettingInspectionStarted,
  } = RECEIVING_QUERY.useSetInspectionStarted();

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const { handleSelectionSkuModalOpen, SelectSkuModal } =
    useSelectDuplicateBarcode<"receivingInspection">(
      scanTypeInfo.scanType === "single"
        ? {
            type: "receivingInspection",
            itemList: scanTypeInfo.itemList,
            setGroupedItemIdInProgress: scanTypeInfo.setGroupedItemIdInProgress,
            setSkuInProgress: scanTypeInfo.setSkuInProgress,
          }
        : {
            type: "receivingInspection",
            itemList: [],
            setGroupedItemIdInProgress: noop,
            setSkuInProgress: noop,
          }
    );

  const {
    addScannedUnverifiedItemToInspection,
    ResponseHandlerOfUpdateUnverifiedItem,
  } = useUnverifiedItem({
    receivingId,
    registeredUnverifiedList,
    onSuccessForCreateUnverifiedItem: () => {
      queryClient.invalidateQueries(
        RECEIVING_QUERY_KEY_GEN.getPDAReceivingDetail({ id: receivingId })
      );
    },
  });

  const handleScanResult = useCallback(
    (scanResult: string) => {
      if (!receivingId || !currentManager) return;

      // 허공이나 손상된 바코드를 스캔하는 경우
      if (scanResult === SCAN_RESULT_READ_FAIL) {
        setValidationError({
          title: (
            <>
              정상 스캔이 되지 않았습니다.
              <br />
              다시 스캔하거나 직접 입력해 주세요.
            </>
          ),
        });

        return;
      }

      let scannedCounterKey: string | undefined;

      if (scanTypeInfo.scanType === "multi") {
        if (!scanTypeInfo.inspectingId) {
          setValidationError({
            title: `분할검수할 항목을 '선택'한 후 '상품스캔'을 진행해주세요.`,
          });
          return;
        }

        // 분할검수의 경우 선택된 inspectingId를 기준으로 바코드를 찾는다.
        scannedCounterKey = getCounterKeyFromScanResultByInspectingIdInProgress(
          {
            counterData: skuCounting,
            scanResult,
            inspectingIdInProgress: scanTypeInfo.inspectingId,
          }
        );
      }

      if (scanTypeInfo.scanType === "single") {
        console.log("skuCounting", skuCounting);

        const itemListWithoutUnverifiedItem = scanTypeInfo.itemList.filter(
          checkForNormalItemAsInspection
        );

        const isGroupedItemSelected = Boolean(
          scanTypeInfo.groupedItemIdInProgress
        );
        console.log(
          "isGroupedItemSelected",
          scanTypeInfo.groupedItemIdInProgress
        );

        /**
         * 상품바코드는 중복되는 경우가 있기 때문에 중복된 바코드를 선택하는 로직이 필요하다.
         * - 선택된 subRow가 있는 경우
         *   - 작업자의 작업 의도가 명확하기 때문에 해당 아이템을 기준으로 기존 로직이 동작
         * - 선택된 subRow가 없는 경우
         *   - 작업자의 의도를 알 수 없기 때문에 중복 바코드 선택 모달을 띄운다.
         */
        if (!isGroupedItemSelected) {
          const incompleteSingleLocationCounterInfoListFromScannedSkuBarcode =
            getIncompleteSingleLocationCounterInfoListFromScannedSkuBarcode({
              counterData: skuCounting,
              scanResult,
            });
          console.log(
            "incompleteSingleLocationCounterInfoListFromScannedSkuBarcode",
            incompleteSingleLocationCounterInfoListFromScannedSkuBarcode
          );

          /**
           * 새로운 검수 작업을 시작하는데, 검수완료되지 않은 중복된 바코드가 있는 경우
           * 작업자가 작업할 아이템을 선택하도록 한다.
           */
          if (
            !skuCounting.skuInProgress &&
            incompleteSingleLocationCounterInfoListFromScannedSkuBarcode.length >
              1
          ) {
            // 아이템을 선택하는 경우 skuInProgress, groupedItemIdInProgress에 등록해서 이후 스캔부터는 해당 아이템이 스캔되도록 한다.
            handleSelectionSkuModalOpen(
              incompleteSingleLocationCounterInfoListFromScannedSkuBarcode
            );
            return;
          }

          /**
           * 검수완료되지 않은 중복된 바코드가 2개 이상 있는 경우
           * 선택 모달에서 등록한 skuInProgress를 기준으로 counterKey를 찾는다.
           */
          if (
            incompleteSingleLocationCounterInfoListFromScannedSkuBarcode.length >
            1
          ) {
            console.log("검수완료되지 않은 중복된 바코드가 2개 이상 있는 경우");
            scannedCounterKey =
              getCounterKeyFromScanResultByInspectingIdInProgress({
                counterData: skuCounting,
                scanResult,
                inspectingIdInProgress: skuCounting.skuInProgress?.inspectingId,
              });
            /**
             * 일반적인 미완료된(검수완료되지 않은 중복 상품 바코드가 1개 남은) 경우
             */
          } else if (
            incompleteSingleLocationCounterInfoListFromScannedSkuBarcode.length ===
            1
          ) {
            console.log(
              "일반적인 미완료된(검수완료되지 않은 중복 상품 바코드가 1개 남은) 경우"
            );
            scannedCounterKey =
              getIncompleteSingleLocationCounterKeyFromScannedSkuBarcode({
                counterData: skuCounting,
                scanResult,
              });
          }
        }

        // scannedCounterKey가 존재하는 경우 중복 바코드 관련 로직으로 스캔할 아이템(counterKey)가 결정된 상태이다.
        const hasTargetItemByDuplicateSkuList = Boolean(scannedCounterKey);
        console.log(
          "hasTargetItemByDuplicateSkuList",
          hasTargetItemByDuplicateSkuList,
          scannedCounterKey
        );

        scannedCounterKey = hasTargetItemByDuplicateSkuList
          ? scannedCounterKey
          : getSingleLocationCounterKeyFromScanResult({
              counterData: skuCounting,
              scanResult,
              itemIdInprogress: scanTypeInfo.groupedItemIdInProgress,
            });

        console.log("scannedCounterKey", scannedCounterKey);

        const target = scannedCounterKey
          ? skuCounting.counter.counterInfo[scannedCounterKey]
          : undefined;

        /**
         * hasTargetItemByDuplicateSkuList가 false인 경우
         * 중복된 바코드가 없으므로 스캔 결과만으로 skuCounting에서 검사할 수 있다.
         */
        const isMultiLocationItem = hasTargetItemByDuplicateSkuList
          ? target?.counterType === "multi"
          : checkIsMultiLocationItem({
              counterData: skuCounting,
              scanResult,
            });
        if (isMultiLocationItem) {
          setValidationError({
            title: `분할검수인 상태에서 스캔이 불가능합니다.`,
          });
          return;
        }

        const isGroupedItem = checkIsGroupedItem(
          itemListWithoutUnverifiedItem,
          target?.skuId
        );
        if (isGroupedItem && !isGroupedItemSelected) {
          setValidationError({
            title: `관리일자를 선택 후 스캔해주세요.`,
          });
          return;
        }

        const isScannedItemInList = !!itemListWithoutUnverifiedItem.some(
          (item) =>
            getFormattedSingleSkuId(item.sku.id) === scanResult ||
            item.sku.barCode === scanResult
        );
        // 관리일자가 지정된 아이템을 선택한 상태에서 리스트 내의 다른 아이템을 스캔한 경우
        if (
          isGroupedItemSelected &&
          isScannedItemInList &&
          /**
           * subRow를 선택한 상태에서는 groupedItemId를 기준으로 counterKey를 찾기 때문에
           * scannedCounterKey가 존재하지 않는다는 것은 선택한 아이템을 스캔하지 않았다는 의미이다.
           */
          !scannedCounterKey
        ) {
          const inProgressItem = itemListWithoutUnverifiedItem.find(
            (item) => item.id === scanTypeInfo.groupedItemIdInProgress
          );

          setValidationError({
            title: `현재 선택한 상품(${getLabelCombinedWithSKUIdAndManagementDate(
              {
                SKUId: inProgressItem?.sku.id,
                managementKind: inProgressItem?.sku.managementKind,
                managementDate: inProgressItem?.managementDate,
              }
            )})의 바코드를 스캔해주세요.`,
          });
          return;
        }
      }

      // 불일치의 경우 (등록된 SKU ID 나 바코드가 아닌 경우)
      if (!scannedCounterKey) {
        addScannedUnverifiedItemToInspection(scanResult);

        return;
      }

      const target = skuCounting.counter.counterInfo[scannedCounterKey];

      console.log("target", target);
      if (target.isCompleteInspecting) {
        setValidationError({
          title: `이미 검수완료한 SKU ID 입니다.`,
        });
        return;
      }

      const otherSkuIsInProgress =
        !!skuCounting.skuInProgress &&
        target.counterKey !== skuCounting.skuInProgress.counterKey;
      if (otherSkuIsInProgress) {
        const title = (() => {
          if (scanTypeInfo.scanType === "multi") {
            return `현재 작업중인 분할 검수를 완료한 후에 다른 분할 검수를 진행할 수 있습니다.`;
          }

          if (scanTypeInfo.scanType === "single") {
            return `현재 작업중인 검수(${getLabelCombinedWithSKUIdAndManagementDate(
              {
                SKUId: skuCounting.skuInProgress?.skuId,
                managementKind: skuCounting.skuInProgress?.managementKind,
                managementDate: skuCounting.skuInProgress?.managementDate,
              }
            )})를 완료한 후에 다른 검수를 진행할 수 있습니다.`;
          }

          return "";
        })();

        setValidationError({
          title,
        });
        return;
      }

      const isInitialScan = !target.inspectorId && !target.current;
      // TODO: 스캔시 담당자 권한 확인 해야함 - 창고의 네트워크 상황때문에 해당 단계 뺐음
      if (isInitialScan) {
        // 1) 작업자 지정 api 호출
        assignInspectorToItem(
          {
            pathParams: {
              receivingId,
              itemId: target.itemId,
            },
            inspectingId: target.inspectingId,
          },
          {
            onSuccess: () => {
              // 2) local count ++
              // 위에서 존재여부 이미 검사함
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              skuCounting.counter.addCountById(scannedCounterKey!);
              skuCounting.setSkuInProgress(target);

              setRowInfoToHighlight({
                rowKey:
                  scanTypeInfo.scanType === "single"
                    ? target.itemId
                    : target.inspectingId,
              });

              // 3) 변경된 상세 데이터 불러오기
              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      } else {
        const isAssignedWorker = target.inspectorId === currentManager.id;
        if (!isAssignedWorker) {
          setValidationError({
            title: "이미 타 담당자가 해당 상품을 검수하고 있습니다.",
          });
          return;
        }

        skuCounting.counter.addCountById(scannedCounterKey);
        if (target.counterKey !== skuCounting.skuInProgress?.counterKey) {
          skuCounting.setSkuInProgress(target);
        }
        setRowInfoToHighlight({
          rowKey:
            scanTypeInfo.scanType === "single"
              ? target.itemId
              : target.inspectingId,
        });
      }
    },
    [
      receivingId,
      currentManager,
      scanTypeInfo,
      skuCounting,
      setValidationError,
      handleSelectionSkuModalOpen,
      addScannedUnverifiedItemToInspection,
      assignInspectorToItem,
      setRowInfoToHighlight,
      queryClient,
    ]
  );

  const checkIsInspectionStarted = useCallback(
    (scanResult: string) => {
      if (!startInspectionAt) {
        setInspectionStarted(
          {
            pathParams: { receivingId },
          },
          {
            onSuccess: () => {
              handleScanResult(scanResult);
            },
          }
        );
        return;
      }

      handleScanResult(scanResult);
    },
    [startInspectionAt, handleScanResult, setInspectionStarted, receivingId]
  );

  useScan(checkIsInspectionStarted);

  const ResultHandlerOfScanSKU = useMemo(
    () => (
      <>
        {ValidationErrorModal}

        {ResponseHandlerOfAssigningInspectorToItem}
        {ResponseHandlerOfSettingInspectionStarted}

        {ResponseHandlerOfUpdateUnverifiedItem}

        {SelectSkuModal}
      </>
    ),
    [
      ValidationErrorModal,
      ResponseHandlerOfAssigningInspectorToItem,
      ResponseHandlerOfSettingInspectionStarted,
      ResponseHandlerOfUpdateUnverifiedItem,
      SelectSkuModal,
    ]
  );

  return {
    ResultHandlerOfScanSKU,
  };
}
