import { useCallback, useMemo, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { WarehousingStatusLabel } from "@sellernote/_shared/src/types/fulfillment/common";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";
import {
  checkUsesManagementDate,
  convertItemListToMapBySKU,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputRadio from "@sellernote/_sds-v1/src/components/input/InputRadio";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";

import { PageType, SelectedData, SkuInprogressValue } from "./types";
import {
  checkNeedsToSelectIsUnverifiedSku,
  checkNeedsToSelectStatusLabel,
  removeDuplicateSku,
  setIsUnverifiedSku,
  setStatusLabel,
} from "./utils";

import Styled from "./index.styles";

const initialSelectedData: SelectedData = {
  skuId: undefined,
};

export default function useSelectDuplicateBarcode<Type extends PageType>({
  type,
  itemList,
  setSkuInProgress,
  setGroupedItemIdInProgress,
}: {
  type: Type;
  itemList: ReceivingItem[];
  setSkuInProgress: (val: SkuInprogressValue<Type> | undefined) => void;
  setGroupedItemIdInProgress: (val: number) => void;
}) {
  const [incompleteDuplicateSkuList, setIncompleteDuplicateSKUList] =
    useState<UseCounterDataValue<SkuInprogressValue<Type>>[]>();
  const [selectedData, setSelectedData] =
    useState<SelectedData>(initialSelectedData);

  const handleSelectionSkuModalOpen = useCallback(
    (list: UseCounterDataValue<SkuInprogressValue<Type>>[]) => {
      setIncompleteDuplicateSKUList(list);
    },
    []
  );

  const handleSelectSkuModalClose = useCallback(() => {
    setIncompleteDuplicateSKUList(undefined);
    setSelectedData(initialSelectedData);
  }, []);

  const isWarehousing =
    type === "receivingWarehousing" || type === "returningWarehousing";

  const skuIdOptionListToSelect = removeDuplicateSku<PageType>(
    incompleteDuplicateSkuList ?? []
  ).map((item) => ({
    label: `${getFormattedSingleSkuId(item.skuId)} - ${item.itemName}`,
    value: item.skuId,
  }));

  const skuListForManagementDateList = (
    incompleteDuplicateSkuList ?? []
  ).filter((item) => {
    const isMatched = item.skuId === selectedData.skuId;
    const usesManagementDate = checkUsesManagementDate({
      managementKind: item.managementKind,
      managementDate: item.managementDate,
    });

    return isMatched && usesManagementDate;
  });

  const managementDateOptionListToSelect = [
    ...new Set(
      [...skuListForManagementDateList]
        .sort((a, b) => {
          if (a.managementDate && b.managementDate) {
            return a.managementDate.localeCompare(b.managementDate);
          }

          return 0;
        })
        .map(({ managementDate }) => managementDate)
    ),
  ].map((managementDate) => ({
    label: toFormattedDate(managementDate, "YYYY-MM-DD"),
    value: managementDate,
  }));

  const selectedSkuIdOption = skuIdOptionListToSelect.find(
    (item) => item.value === selectedData.skuId
  );

  const selectedManagementDateOption = managementDateOptionListToSelect.find(
    (item) => item.value === selectedData.managementDate
  );

  const needsToSelectManagementDate = Boolean(
    managementDateOptionListToSelect.length > 0
  );

  const needsToSelectStatusLabel = (() => {
    if (!isWarehousing) {
      return false;
    }

    const statusLabelList = (incompleteDuplicateSkuList ?? []).reduce<string[]>(
      (acc, item) => {
        const isSkuIdMatched = item.skuId === selectedData.skuId;
        const isManagementDateMatched = selectedData.managementDate
          ? item.managementDate === selectedData.managementDate
          : true;

        if (isSkuIdMatched && isManagementDateMatched) {
          if ("statusLabel" in item) {
            acc.push(item.statusLabel);
            return acc;
          }
        }

        return acc;
      },
      []
    );

    const hasDuplicateStatusLabel = new Set(statusLabelList).size > 1;

    return hasDuplicateStatusLabel;
  })();

  const needsToSelectIsUnverifiedSku = (() => {
    if (!isWarehousing) {
      return false;
    }

    const selectedList = (incompleteDuplicateSkuList ?? []).filter((item) => {
      if ("statusLabel" in item) {
        const isSkuIdMatched = item.skuId === selectedData.skuId;
        const isManagementDateMatched = selectedData.managementDate
          ? item.managementDate === selectedData.managementDate
          : true;
        const isStatusLabelMatched = selectedData.statusLabel
          ? item.statusLabel === selectedData.statusLabel
          : true;

        return (
          isSkuIdMatched && isManagementDateMatched && isStatusLabelMatched
        );
      }

      return false;
    });

    const hasUnverifiedSku = selectedList.length > 1;

    return hasUnverifiedSku;
  })();

  const isSelectSKUIdModalOpen = Boolean(incompleteDuplicateSkuList);

  const isValid = (() => {
    if (isWarehousing) {
      return Boolean(
        selectedData.skuId &&
          (needsToSelectManagementDate ? selectedData.managementDate : true) &&
          selectedData.statusLabel &&
          selectedData.isUnverifiedSku !== undefined
      );
    }

    if (needsToSelectManagementDate) {
      return Boolean(selectedData.skuId && selectedData.managementDate);
    }

    return Boolean(selectedData.skuId);
  })();

  const handleSelectionCompleteSubmit = useCallback(() => {
    const selectedItem = incompleteDuplicateSkuList?.find((item) => {
      const isSkuIdMatched = item.skuId === selectedData.skuId;
      const usesManagementDate = checkUsesManagementDate({
        managementKind: item.managementKind,
        managementDate: item.managementDate,
      });
      const isManagementDateMatched = usesManagementDate
        ? item.managementDate === selectedData.managementDate
        : true;
      const isStatusLabelMatched =
        "statusLabel" in item
          ? item.statusLabel === selectedData.statusLabel
          : true;
      const isUnverifiedSkuMatched =
        "isUnverifiedSku" in item
          ? item.isUnverifiedSku === selectedData.isUnverifiedSku
          : true;

      if (!usesManagementDate) {
        if (!isWarehousing) {
          return isSkuIdMatched;
        }

        return isSkuIdMatched && isStatusLabelMatched && isUnverifiedSkuMatched;
      }

      if (!isWarehousing) {
        return isSkuIdMatched && isManagementDateMatched;
      }

      return (
        isSkuIdMatched &&
        isManagementDateMatched &&
        isStatusLabelMatched &&
        isUnverifiedSkuMatched
      );
    });

    setSkuInProgress(selectedItem);
    handleSelectSkuModalClose();

    const selectedItemList = (convertItemListToMapBySKU(itemList) ?? [])[
      selectedData.skuId ?? 0
    ];
    const usesSubRow = selectedItemList?.length > 1;
    if (usesSubRow) {
      setGroupedItemIdInProgress(selectedItem?.itemId ?? 0);
    }
  }, [
    handleSelectSkuModalClose,
    incompleteDuplicateSkuList,
    isWarehousing,
    itemList,
    selectedData.isUnverifiedSku,
    selectedData.managementDate,
    selectedData.skuId,
    selectedData.statusLabel,
    setGroupedItemIdInProgress,
    setSkuInProgress,
  ]);

  const handleIsUnverifiedSkuSelect = useCallback(
    (selectedIsUnverifiedSku: boolean) => {
      setSelectedData((prev) => ({
        ...prev,
        isUnverifiedSku: selectedIsUnverifiedSku,
      }));
    },
    []
  );

  const handleStatusLabelSelect = useCallback(
    (selectedStatusLabel: WarehousingStatusLabel) => {
      if (isWarehousing) {
        const filteredSkuList = (incompleteDuplicateSkuList ?? []).filter(
          (item) => {
            if ("statusLabel" in item) {
              if (
                item.skuId === selectedData.skuId &&
                item.managementDate === selectedData.managementDate &&
                item.statusLabel === selectedStatusLabel
              ) {
                return true;
              }

              return false;
            }

            return false;
          }
        );
        const needsToSelectIsUnverifiedSku =
          checkNeedsToSelectIsUnverifiedSku(filteredSkuList);

        setIsUnverifiedSku({
          needsToSelectIsUnverifiedSku,
          skuList: filteredSkuList,
          handleIsUnverifiedSkuSelect,
        });
      }

      setSelectedData((prev) => ({
        ...prev,
        statusLabel: selectedStatusLabel,
      }));
    },
    [
      handleIsUnverifiedSkuSelect,
      incompleteDuplicateSkuList,
      isWarehousing,
      selectedData.managementDate,
      selectedData.skuId,
    ]
  );

  const handleManagementDateSelect = useCallback(
    (selectedManagementDateItem: InputSelectOption<string | undefined>) => {
      if (isWarehousing) {
        const filteredSkuList = (incompleteDuplicateSkuList ?? []).filter(
          (item) =>
            item.skuId === selectedData.skuId &&
            item.managementDate === selectedManagementDateItem.value
        );
        const needsToSelectStatusLabel =
          checkNeedsToSelectStatusLabel(filteredSkuList);
        const needsToSelectIsUnverifiedSku =
          checkNeedsToSelectIsUnverifiedSku(filteredSkuList);

        setStatusLabel({
          needsToSelectStatusLabel,
          skuList: filteredSkuList,
          handleStatusLabelSelect,
        });

        setIsUnverifiedSku({
          needsToSelectIsUnverifiedSku,
          skuList: filteredSkuList,
          handleIsUnverifiedSkuSelect,
        });
      }

      setSelectedData((prev) => ({
        ...prev,
        managementDate: selectedManagementDateItem.value,
      }));
    },
    [
      handleIsUnverifiedSkuSelect,
      handleStatusLabelSelect,
      incompleteDuplicateSkuList,
      isWarehousing,
      selectedData.skuId,
    ]
  );

  const handleSkuIdSelect = useCallback(
    (selectedSkuIdItem: InputSelectOption<number>) => {
      if (isWarehousing) {
        /**
         * 관리일자 선택이 필요 없는 경우에는 상태 라벨, 불일치 여부를 즉시 설정
         */
        if (!needsToSelectManagementDate) {
          const filteredSkuList = (incompleteDuplicateSkuList ?? []).filter(
            (item) => item.skuId === selectedSkuIdItem.value
          );
          const needsToSelectStatusLabel = checkNeedsToSelectStatusLabel(
            incompleteDuplicateSkuList
          );
          const needsToSelectIsUnverifiedSku =
            checkNeedsToSelectIsUnverifiedSku(incompleteDuplicateSkuList);

          setStatusLabel({
            needsToSelectStatusLabel,
            skuList: filteredSkuList,
            handleStatusLabelSelect,
          });

          setIsUnverifiedSku({
            needsToSelectIsUnverifiedSku,
            skuList: filteredSkuList,
            handleIsUnverifiedSkuSelect,
          });
        }
      }

      setSelectedData((prev) => ({
        ...prev,
        skuId: selectedSkuIdItem.value,
        managementDate: undefined,
      }));
    },
    [
      handleIsUnverifiedSkuSelect,
      handleStatusLabelSelect,
      incompleteDuplicateSkuList,
      isWarehousing,
      needsToSelectManagementDate,
    ]
  );

  console.log("selectedData", selectedData);

  const showsSelectManagementDate = needsToSelectManagementDate;

  const showsSelectStatusLabel = (() => {
    if (!isWarehousing) {
      return false;
    }

    if (!needsToSelectManagementDate) {
      return needsToSelectStatusLabel;
    }

    return selectedData.managementDate && needsToSelectStatusLabel;
  })();

  const showsSelectIsUnverifiedSku = (() => {
    if (!isWarehousing) {
      return false;
    }

    if (!needsToSelectManagementDate) {
      return needsToSelectIsUnverifiedSku;
    }

    return selectedData.managementDate && needsToSelectIsUnverifiedSku;
  })();

  const SelectSkuModal = useMemo(
    () => (
      <Modal
        active={isSelectSKUIdModalOpen}
        title="상품 바코드에 해당하는 SKU ID 선택"
        uiType="contentWithCustomBody"
        body={
          <Styled.selectDuplicateBarcodeModalBody>
            <Styled.modalItemContainer>
              <Styled.modalItem>
                <Styled.description>
                  상품 바코드가 동일한 상품이 있습니다.
                  <br />
                  스캔한 상품에 해당되는 SKU ID를 선택해주세요.
                </Styled.description>

                <InputSelect<number>
                  uiType="outline"
                  optionList={skuIdOptionListToSelect}
                  label="SKU ID 선택"
                  selectedOption={selectedSkuIdOption}
                  handleSelect={handleSkuIdSelect}
                />
              </Styled.modalItem>

              {showsSelectManagementDate && (
                <Styled.modalItem>
                  <Styled.description>
                    관리일자가 지정된 상품입니다.
                    <br />
                    일자를 선택해주세요.
                  </Styled.description>

                  <InputSelect<string | undefined>
                    uiType="outline"
                    optionList={managementDateOptionListToSelect}
                    label={`${
                      skuListForManagementDateList[0]?.managementKind
                        ? INVENTORY_MANAGEMENT_KIND_MAP[
                            skuListForManagementDateList[0].managementKind
                          ]
                        : "관리일자"
                    } 선택`}
                    selectedOption={selectedManagementDateOption}
                    handleSelect={handleManagementDateSelect}
                  />
                </Styled.modalItem>
              )}

              {showsSelectStatusLabel && (
                <Styled.modalItem>
                  <Styled.description>
                    선택한 항목이 정상, 불량 상태가 혼재되어 있습니다.
                    <br />
                    상태를 선택해주세요.
                  </Styled.description>

                  <InputRadio
                    direction="row"
                    optionList={[
                      {
                        label: "정상",
                        value: "정상",
                      },
                      {
                        label: "불량",
                        value: "불량",
                      },
                    ]}
                    selectedValue={selectedData.statusLabel}
                    selectCallback={handleStatusLabelSelect}
                  />
                </Styled.modalItem>
              )}

              {showsSelectIsUnverifiedSku && (
                <Styled.modalItem>
                  <Styled.description>
                    선택한 항목의 상태에 불일치상품인 SKU,
                    <br />
                    불일치상품이 아닌 SKU가 혼재되어 있습니다.
                    <br />
                    작업할 상품을 선택해주세요.
                  </Styled.description>

                  <InputRadio
                    direction="row"
                    optionList={[
                      {
                        label: "불일치 상품이 아닌 상품",
                        value: false,
                      },
                      {
                        label: "불일치 상품",
                        value: true,
                      },
                    ]}
                    selectedValue={selectedData.isUnverifiedSku}
                    selectCallback={handleIsUnverifiedSkuSelect}
                  />
                </Styled.modalItem>
              )}
            </Styled.modalItemContainer>

            <Button
              theme="primary"
              size="normal"
              label="선택 완료"
              disabled={!isValid}
              handleClick={handleSelectionCompleteSubmit}
            />
          </Styled.selectDuplicateBarcodeModalBody>
        }
        onClose={handleSelectSkuModalClose}
      />
    ),
    [
      isSelectSKUIdModalOpen,
      skuIdOptionListToSelect,
      selectedSkuIdOption,
      handleSkuIdSelect,
      showsSelectManagementDate,
      managementDateOptionListToSelect,
      skuListForManagementDateList,
      selectedManagementDateOption,
      handleManagementDateSelect,
      showsSelectStatusLabel,
      selectedData.statusLabel,
      selectedData.isUnverifiedSku,
      handleStatusLabelSelect,
      showsSelectIsUnverifiedSku,
      handleIsUnverifiedSkuSelect,
      isValid,
      handleSelectionCompleteSubmit,
      handleSelectSkuModalClose,
    ]
  );

  return {
    handleSelectionSkuModalOpen,
    SelectSkuModal,
  };
}
